export const REPs = [
    {
        providerId: '183',
        providerName: 'Champion',
        host: true
    },
    {
        providerId: '163',
        providerName: 'Direct Energy'
    },
    {
        providerId: '176',
        providerName: 'TXU Energy'
    },
    {
        providerId: '159',
        providerName: 'Reliant'
    },
    {
        providerId: '206',
        providerName: 'Green Mountain'
    },
    {
        providerId: '258',
        providerName: 'Value Power'
    }
];

export const API_KEY = 'dda73f0618a49d2dfc454e20f7d8fab7';
export const API_URL = 'https://bb0xg7o51l.execute-api.us-east-2.amazonaws.com/beta';
export const POWERLEGO_API_URL = 'https://www.powerlego.com/ApiGateway';
export const GOOGLE_API_KEY = 'AIzaSyCsreMGj8xStaCAs9V2HjMizheuJaVlII0';