import { StyledFooter } from './styles/common.styled';
import moment from 'moment';

export const Footer = () => {
    return (
        <StyledFooter>
            <div>
                <a href="https://www.championenergyservices.com/Terms-Conditions">Terms &amp; Conditions</a>
                <a href="https://www.championenergyservices.com/Privacy-Policy">Privacy Policy</a>
            </div>
            <p>©{moment().format('YYYY')} Champion Energy Services, LLC</p>
            <p>PUCT No. 10098 / IL ICC No. 10-0168 / PA PUC A-2009-2124113 / OH PUC 09-166E(1) / NJ BPU No. ESL-0082 / MD No. IR-2196</p>
        </StyledFooter>
    )
}