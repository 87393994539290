import React, { useState, useEffect, useRef, useContext } from 'react';
import {
    Button, Checkbox, FormControl, FormControlLabel, TextField,
    Grid,
    Box
} from '@mui/material';
import { useLocation } from 'react-router-dom';
// import qs from 'qs';
import type { TduGetInfoProps, MyAddressContextProps } from '../../types';
import { getConsentId, getUsageViaTdu } from '../../fetch/tdu';
import { RollingTextLoading } from '../RollingTextLoading';
import { TduContactLaterSection } from '../styles/get-usage.styled';
import { MyAddressContext } from '../../context-manager';
import { withSnackbar } from '../SnackbarHOC';

const TduGetInfo: React.FC<TduGetInfoProps> = ({
    handleBack,
    hideBack,
    showSmtView,
    showMyaccountView,
    continueWithForecast,
    handleSetUsage,
    snackbarShowMessage
}) => {
    const location = useLocation();
    const [ validated, setValidated ] = useState(false);
    const [ showConsentError, setShowConsentError ] = useState(false);
    const [ email, setEmail ] = useState('');
    const [ fname, setFname ] = useState('');
    const [ lname, setLname ] = useState('');
    const [ submitting, setSubmitting ] = useState(false);
    const [ isAgree, setIsAgree ] = useState(false);
    const [ contactLater, setContactLater ] = useState(false);
    const { myAddress, utilityCode } = useContext(MyAddressContext) as MyAddressContextProps;
    const timer: any = useRef(null);
    
    useEffect(() => {
        return () => {
            if (timer) clearTimeout(timer.current);
        }
    }, [])

    const handleSubmit = (evt: React.FormEvent) => {
        evt.preventDefault();
        evt.stopPropagation();

        const { esiid, zipcode } = myAddress || {};
        const form = evt.currentTarget as HTMLInputElement;
        setValidated(true);
        if (!isAgree) {
            setShowConsentError(true);
            return;
        }
        if (!form.checkValidity() || !esiid || !zipcode || submitting) return;

        setSubmitting(true);
        getConsentId(
            esiid,
            email,
            '', //phone
            zipcode,
            `${fname} ${lname}`,
            utilityCode
        ).then(res => {
            if (res && res.response && res.response.consent_id) {
                if (res.response.email === 1) {
                    setContactLater(true);
                    return;
                }
                fetchUsageWithTimeout(res.response.consent_id);
            }
            else {
                snackbarShowMessage?.('An error occurred', 'error');
                // console.error('An error occurred');
                setSubmitting(false);
            }
        })
    }

    const fetchUsageWithTimeout = (consent_id: string) => {
        const controller = new AbortController();
        timer.current = setTimeout(() => controller.abort(), 120000);
        fetchUsage(consent_id, controller);
    };

    const fetchUsage = (consent_id: string, controller: any) => {
        getUsageViaTdu(
            consent_id,
            {signal: controller.signal}
        ).then(res => {
            if (res) {
                if (parseInt(res.status) === 1) {
                    clearTimeout(timer.current);
                    const data = res.data;
                    handleSetUsage(data);
                    // let usage: string[] = [];
                    // data.forEach((item: {
                    //     'actual_kwh': string
                    // }) => {
                    //     usage.push(item['actual_kwh']);
                    // });
                    // const { address, esiid, zipcode, city, state } = qs.parse(location.search, { ignoreQueryPrefix: true });
                    // navigate(`/plans?address=${encodeURIComponent(`${address}`)}&esiid=${esiid}&zipcode=${zipcode}&city=${city}&state=${state}&actualusage=${usage.join('-')}`);
                }
                if (parseInt(res.status) === 0) {
                    setTimeout(() => fetchUsage(consent_id, controller), 10000);
                }
            }
            else {
                snackbarShowMessage?.('An error occurred', 'error');
                // console.error('An error occurred');
                clearTimeout(timer.current);
                setSubmitting(false);
            }
        }).catch(() => {
            setContactLater(true);
            clearTimeout(timer.current);
        })
    }

    if (contactLater) {
        return <ContactLater
                    handleUseSmt={showSmtView}
                    handleUseMyaccount={showMyaccountView}
                    handleUseForecast={continueWithForecast}
                />
    }

    return (
        <Box position="relative">
            <h2>We are almost there with your usage data</h2>
            <p>You must grant us permission to your usage data by providing information below</p>
            <form onSubmit={handleSubmit}>
                <FormControl fullWidth margin="normal">
                    <TextField
                        id="first-name"
                        name="first-name"
                        label="First Name *"
                        type="string"
                        value={fname}
                        onChange={(evt) => setFname(evt.target.value)}
                        disabled={submitting}
                        fullWidth
                    />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <TextField
                        id="last-name"
                        name="last-name"
                        label="Last Name *"
                        type="string"
                        value={lname}
                        onChange={(evt) => setLname(evt.target.value)}
                        disabled={submitting}
                        fullWidth
                    />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <TextField
                        id="email"
                        name="email"
                        label="Email *"
                        type="email"
                        value={email}
                        onChange={(evt) => setEmail(evt.target.value)}
                        disabled={submitting}
                        fullWidth
                    />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <FormControlLabel
                        control={<Checkbox checked={isAgree}
                        onClick={() => {
                            setShowConsentError(false);
                            setIsAgree(!isAgree);
                        }} />}
                        label={
                            <span>
                                I authorize my utility company to grant Champion access to the information as stated in the <a href='/files/Standard-Letter-of-Authorization-for-Request-of-Historical-Usage-Information-English-.pdf' target="_blank" rel="noreferrer" style={{color:'#005daa'}}>Letter of Authorization</a>
                            </span>
                        }
                        disabled={submitting}
                    />
                </FormControl>
                <Grid container sx={{mt:5}}>
                    {
                        !hideBack &&
                        <Button type="button" size="large" variant="outlined" disabled={submitting} onClick={() => { handleBack() }} sx={{mr:4}}>
                            Back
                        </Button>
                    }
                    <Button type="submit" size="large" variant="contained" disabled={submitting}>Continue</Button>
                </Grid>
            </form>
            <RollingTextLoading
                text={[
                    'This process may take upto 2 minutes',
                    'We are connecting with the utility company...',
                    "We are retrieving your usage data from TDU's database...",
                    'Analyzing data...',
                    'Be patient while we are comparing plans...',
                    'We are pulling your personalized electricity plan recommendations',
                    'Start saving in just a few seconds!',
                    'We are almost there!'
                ]}
                show={submitting}
            />
        </Box>
    )
}

const ContactLater = ({
    handleUseSmt,
    handleUseMyaccount,
    handleUseForecast
}: {
    handleUseSmt: Function
    handleUseMyaccount: Function
    handleUseForecast: Function
}) => {
    return (
        <div>
            <h4 style={{fontSize:28}}>Thank you for your patience</h4>
            <p>But it seems like your address does not support instant data sharing with your utility company at this time.</p>
            <div>
                <Button variant="contained" onClick={() => {handleUseForecast()}}>Continue without Usage Data</Button>
            </div>
            <TduContactLaterSection>
                <p>Or Click below to get your usage data by logging on to your existing energy provider's account or manually entering Smart Meter Texas (SMT) number</p>
                <div id="get-usage-option-wrapper">
                    <div className="get-usage-option" onClick={() => handleUseSmt()}>
                        <img src="/images/icon-smart-meter.png" width="64" alt="" />
                        <div>
                            <h5>Use Smart Meter Texas (SMT) Data</h5>
                            <p>Instant access of your usage history through your smart meter number</p>
                        </div>
                    </div>
                    <div className="get-usage-option" onClick={() => handleUseMyaccount()}>
                        <img src="/images/icon-myaccount.png" width="64" alt="" />
                        <div>
                            <h5>MyAccount</h5>
                            <p>Instant access of your usage history through MyAccount</p>
                        </div>
                    </div>
                </div>
            </TduContactLaterSection>
        </div>
    )
}

export default withSnackbar(TduGetInfo)