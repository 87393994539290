import React, { useState, useEffect, useRef, useContext } from 'react';
import {
    Button, TextField, MenuItem, FormControl, FormControlLabel,
    Checkbox, Grid, Box
} from '@mui/material';
import Select, { SelectChangeEvent } from "@mui/material/Select"
import { useLocation } from 'react-router-dom';
// import { CheckboxItem } from '../CheckboxItem';
import {
    fetchRepList,
    newAgreement,
    getMyAccountInfo
} from '../../fetch/fetch'
// import qs from 'qs';
import type { Rep, SmtGetInfoProps, MyAddressContextProps } from '../../types';
import { FormError } from '../FormError';
import { RollingTextLoading } from '../RollingTextLoading';
import { MyAddressContext } from '../../context-manager';
import { withSnackbar } from '../SnackbarHOC';

// 4change, bulb, green mountain, pepco, veteran
const unsupportedRepList = ['GME', 'VTR'];

const MyAccountInfo: React.FC<SmtGetInfoProps> = ({ handleShowAgreement, handleBack, hideBack, snackbarShowMessage }) => {
    const location = useLocation();
    const [ providers, setProviders ] = useState<Rep[]>([]);
    const [ loadingProviders, setLoadingProviders ] = useState(false);
    const [ validated, setValidated ] = useState(false);
    const [ selectedProvider, setSelectedProvider ] = useState<string>('');
    const [ showProviderError, setShowProviderError ] = useState(false);
    const [ showConsentError, setShowConsentError ] = useState(false);
    const [ submitting, setSubmitting ] = useState(false);
    const [ isAgree, setIsAgree ] = useState(false);
    const [ username, setUsername ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ isUnsupportedRep, setIsUnsupportedRep ] = useState(false);
    const { myAddress, utilityCode } = useContext(MyAddressContext) as MyAddressContextProps;
    const startWithOne = useRef(false);
    
    useEffect(() => {
        setLoadingProviders(true)
        fetchRepList().then(res => {
            if (res) setProviders(res)
            setLoadingProviders(false)
        })
    }, [])

    const getSelectedProviderObj = (value: string) => {
        if (providers) {
            return providers.filter(val => val.name === value)[0];
        }
        return undefined;
    }

    const handleSubmit = (evt: React.FormEvent) => {
        evt.preventDefault();
        evt.stopPropagation();

        const esiid = myAddress?.esiid;
        const form = evt.currentTarget as HTMLInputElement;
        const validProvider = handleValidProvider()
        setValidated(true);
        if (!isAgree) {
            setShowConsentError(true);
            return;
        }
        if (!form.checkValidity() || !validProvider || !selectedProvider || !esiid || submitting || isUnsupportedRep) return;

        setSubmitting(true);
        
        const { certificate_number, value } = providers.filter((val) => val.name === selectedProvider)[0];
        getMyAccountInfo(
            username,
            password,
            value
        ).then(res => {
            if (res && parseInt(res.status) === 1) {
                if (res.meter_id.length > 0) {
                    const meterNumber = `${res.meter_id[0]}`;
                    if (meterNumber.substr(0, 1) === '1') startWithOne.current = true;
                    let email = '';
                    
                    // eslint-disable-next-line
                    let pattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
                    if (pattern.test(username)) {
                        email = username;
                    }
                    else {
                        email = `rep_${username}@champion.com`;
                    }

                    getAgreement(
                        `${esiid}`,
                        meterNumber,
                        certificate_number,
                        email
                    );
                }
                else {
                    setSubmitting(false);
                    snackbarShowMessage?.('Meter number not found', 'error');
                    // console.error('Meter number not found');
                }
            }
            else {
                setSubmitting(false);
                snackbarShowMessage?.('Login failed', 'error');
                // console.error('Login failed');
            }
        })
    }

    const getAgreement = (esiid: string, meterNumber: string, certificateNumber: string, email: string) => {
        newAgreement(
            esiid,
            meterNumber,
            certificateNumber,
            email,
            '0'
        ).then(res => {
            if (res && res.response && res.response.consent_id) {
                handleShowAgreement(res.response.consent_id, email);
            }
            else {
                if (startWithOne.current === true) {
                    startWithOne.current = false;
                    getAgreement(esiid, meterNumber.substr(1), certificateNumber, email);
                }
                else {
                    if (res.error_code && res.error_code === '500') {
                        snackbarShowMessage?.('Incorrect ESIID-METER-REP Combination', 'error');
                        // console.error('Incorrect ESIID-METER-REP Combination');
                    }
                    setSubmitting(false);
                    console.error('error: new agreement');
                }
            }
        })
    }

    const handleValidProvider = () => {
        let isValid = true
        setShowProviderError(false)
        if (!selectedProvider){
            isValid = false
            setShowProviderError(true)
        }
        return isValid
    }

    const Placeholder = ({ children }: { children: string }) => <div style={{color:'#666'}}>{children}</div>

    return (
        <Box position="relative">
            <h2>We are almost there!</h2>
            <p>We will need to access your Retail Energy Provider (REP) Account and have your permission to pull usage from Smart Meter Texas (SMT)</p>
            <form onSubmit={handleSubmit}>
                <FormControl fullWidth margin="normal">
                    <Select
                        labelId="rep-select-label"
                        id="rep-select"
                        value={selectedProvider}
                        onChange={(evt: SelectChangeEvent) => {
                            const value = evt.target.value;
                            const provider = getSelectedProviderObj(value);
                            setSelectedProvider(value)
                            if (provider) {
                                setIsUnsupportedRep(unsupportedRepList.indexOf(provider.value) >= 0);
                            }
                            setShowProviderError(false)
                        }}
                        disabled={submitting}
                        displayEmpty={true}
                        renderValue={selectedProvider !== '' ? undefined : () => <Placeholder>Select...</Placeholder>}
                    >
                        {
                            providers.map((val, idx) => <MenuItem value={val.name} key={idx}>{val.name}</MenuItem>)
                        }
                    </Select>
                </FormControl>
                {
                    isUnsupportedRep &&
                    <div>
                        <FormError message="Your selected energy provider does not support data sharing via MyAccount. Please choose another method to get your historical usage data" />
                    </div>
                }
                <FormControl fullWidth margin="normal">
                    <TextField
                        id="username"
                        name="username"
                        label="Username"
                        type="string"
                        value={username}
                        onChange={(evt) => setUsername(evt.target.value)}
                        disabled={submitting}
                    />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <TextField
                        id="password"
                        name="password"
                        label="Password"
                        type="string"
                        value={password}
                        onChange={(evt) => setPassword(evt.target.value)}
                        disabled={submitting}
                    />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <FormControlLabel
                        control={<Checkbox checked={isAgree}
                        onClick={() => {
                            setShowConsentError(false);
                            setIsAgree(!isAgree)
                        }} />}
                        label={
                            <span>
                                By clicking on the box, I agree to grant Champion permission to access my account on Smart Meter Texas (SMT). In addition, Champion shall provide all SMT email communications received by Champion on your behalf to your email address provided.
                            </span>
                        }
                        disabled={submitting}
                    />
                </FormControl>
                <Grid container sx={{mt:5}}>
                    {
                        !hideBack &&
                        <Button type="button" variant="outlined" disabled={submitting} onClick={() => { handleBack() }} sx={{mr:4}}>Back</Button>
                    }
                    <Button type="submit" variant="contained" disabled={submitting}>Continue</Button>
                </Grid>
            </form>
            <RollingTextLoading
                text={[
                    'This process may take upto 2 minutes',
                    'We are connecting with your Retail Energy Provider(REP)...'
                ]}
                show={submitting}
            />
        </Box>
    )
}

export default withSnackbar(MyAccountInfo)