import React, { useState, useEffect, useRef } from "react"
import { useNavigate, useLocation } from "react-router-dom";
import {
    Button, Container, TextField, Grid, MenuItem,
    InputAdornment, ButtonGroup
} from "@mui/material"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import { SectionWrapper } from "./styles/saving-section.styled";
import { EntrySection, SavingRow } from "./styles/common.styled";
import { CompareChart } from "./CompareChart";
import { fetchTerms, fetchUtility, fetchPlan } from "../fetch/fetch";
import ReactLoading from "react-loading";
import { REPs } from "../config";
import { PlanTypes, ChartDataTypes, UtilityTypes } from "../types";
import NumberFormat from 'react-number-format';
import { PlanModal } from "./PlanModal";
import { RollingNumber } from "./RollingNumber";
import { Box } from "@mui/system";
import { withSnackbar } from './SnackbarHOC';

const SavingSection = ({
    snackbarShowMessage
}: {
    snackbarShowMessage?: Function;
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [ zipcode, setZipcode ] = useState('');
    const [ selectedTerm, setSelectedTerm ] = useState('');
    const [ utilities, setUtilities ] = useState<UtilityTypes[]>([]);
    const [ selectedUtility, setSelectedUtility ] = useState('');
    const [ termOptions, setTermOptions ]= useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ avgMonthlyUse, setAvgMonthlyUse ] = useState('1000');
    const [ chartData, setChartData ] = useState<ChartDataTypes[]>([]);
    const [ showModal, setShowModal ] = useState(false);
    const [ oneRepPlanList, setOneRepPlanList ] = useState<PlanTypes[]>([]);
    const [ plans, setPlans ] = useState<{[key: string]: PlanTypes[]}|undefined>(undefined);
    const mainRef = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
        if (zipcode.length === 5 && !loading) {
            setLoading(true);
            setSelectedUtility('');
            setUtilities([]);
            setSelectedTerm('');
            setChartData([]);
            getUtility();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zipcode])

    const getUtility = () => {
        fetchUtility(zipcode).then(res => {
            if (res && res[0] && res[0].utility_code) {
                setUtilities(res);

                if (res[0] && res[0].utility_code) {
                    setSelectedUtility(res[0].utility_name);
                }
            }
            else {
                snackbarShowMessage?.(res.message, 'error');
                setLoading(false);
            }
        })
    }

    useEffect(() => {
        const utilityCode = findUtilityCode();
        
        if (utilityCode) {
            setSelectedTerm('');
            
            fetchTerms(utilityCode).then(res => {
                if (res && res.code === 1) {
                    let terms = res.response;
                    terms.sort((a: string, b: string) => {
                        if (parseInt(a) < parseInt(b)) return -1;
                        if (parseInt(a) > parseInt(b)) return 1;
                        return 0;
                    });
                    setTermOptions(terms);
                    if (terms.length > 0) {
                        if (terms.indexOf('12') >= 0) {
                            setSelectedTerm('12');
                        }
                        else {
                            setSelectedTerm(terms[0]);
                        }
                    }
                }
                else {
                    snackbarShowMessage?.(res.msg, 'error');
                    setLoading(false);
                }
            })
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedUtility])

    useEffect(() => {
        if (selectedTerm && selectedUtility) {
            if (utilities.length > 1 && chartData.length === 0) {
                setLoading(false);
            }
            else {
                getPlan();
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTerm, avgMonthlyUse])

    const handleScroll = () => {
        if (mainRef && mainRef.current) {
            mainRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }

    const findUtilityCode = () => {
        if (!selectedUtility) return '';

        return utilities.filter((val) => val.utility_name === selectedUtility)[0].utility_code;
    }

    const getPlan = () => {
        const usages = new Array(12).fill(parseInt(avgMonthlyUse));

        const utilityCode = findUtilityCode();

        setLoading(true);
        fetchPlan(
            parseInt(selectedTerm),
            utilityCode,
            usages
        ).then(res => {
            setLoading(false);

            let data = [];

            if (res && res.code === 1) {
                const allPlans = res.response;
                for (let i in allPlans) {
                    const plans = allPlans[i];
                    let totalRate = 0;
                    plans.forEach((val: PlanTypes, idx: number) => {
                        const { total_rate } = val;
                        totalRate += total_rate;
                    });
                    
                    data.push({
                        id: i,
                        value: Math.ceil(totalRate / plans.length * 100) / 100,
                        label: REPs.filter(val => val.providerId === i)[0].providerName
                    });
                }

                setPlans(allPlans);
                handleSetChartData(data);
                setTimeout(() => {
                    handleScroll();
                }, 200);
            }
            else {
                snackbarShowMessage?.(res.msg, 'error');
            }
        })
    }

    const handleSetChartData = (data: ChartDataTypes[]) => {
        let arr = data;

        arr.sort((a, b) => {
            if (a.value < b.value) return -1;
            if (a.value > b.value) return 1;
            return 0;
        });

        let _chartData = [];
        const hostRep = REPs.filter(val => val.host === true);
        const hostData = arr.filter(val => val.id === hostRep[0].providerId);
        
        if (hostData[0]) {
            _chartData.push(hostData[0]);
        }

        const hostDataIdx = arr.findIndex(val => val.id === hostData[0].id);
        arr.splice(hostDataIdx, 1);
        _chartData = _chartData.concat(arr.slice(-3));
        
        setChartData(_chartData);
    }

    const handleChangeZipcode = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const onlyNums = evt.target.value.replace(/[^0-9]/g, '');
        setZipcode(onlyNums);
    }

    let energySavings = 0;
    if (chartData && chartData.length > 0) {
        const rateDiff = chartData[chartData.length - 1].value - chartData[0].value;
        energySavings = rateDiff * parseFloat(avgMonthlyUse) * parseInt(selectedTerm) / 100;
    }

    return (
        <SectionWrapper>
            <EntrySection>
                <Container>
                    <Grid container position="relative" alignItems="center" sx={{pt:3,pb:3,pl:6,pr:6,backgroundColor:'#ffffffbf'}}>
                        <Grid item xs={12} sm={6} position="relative" zIndex="1" textAlign="left">
                            <h2 style={{marginTop:0}}>See How Our Low Rates Compare!</h2>
                            <p>Enter your zip code and see how much you can save when compared against others</p>
                            <Grid container spacing={3} sx={{mt:4,textAlign:'center'}}>
                                <Grid item xs={12} md={5}>
                                    <TextField
                                        label="Your Zip Code"
                                        value={zipcode}
                                        onChange={handleChangeZipcode}
                                        inputProps={{ maxLength: 5 }}
                                        InputProps={{
                                            endAdornment: loading ? <InputAdornment position="end"><ReactLoading type="bubbles" color="#bbb" width={36} height={36} /></InputAdornment> : null
                                        }}
                                        fullWidth
                                        disabled={loading}
                                    />
                                </Grid>
                                {
                                    (utilities && utilities.length > 1) &&
                                    <Grid item xs={12} md={5}>
                                        <Select
                                            labelId="utility-label"
                                            id="utility"
                                            value={selectedUtility}
                                            onChange={(evt: SelectChangeEvent) => {
                                                setSelectedUtility(evt.target.value as string);
                                            }}
                                            disabled={loading}
                                            fullWidth
                                            sx={{textAlign:'left'}}
                                        >
                                            {
                                                utilities.map((val: any, idx) => <MenuItem value={val.utility_name} key={idx}>{val.utility_name}</MenuItem>)
                                            }
                                        </Select>
                                    </Grid>
                                }
                            </Grid>
                            {
                                (utilities && utilities.length > 1) &&
                                <Box sx={{mt:4,textAlign:{xs:'center',sm:'left'}}}>
                                    <Button
                                        size="large"
                                        variant="contained"
                                        onClick={getPlan}
                                        disabled={loading || !zipcode || !selectedUtility}
                                    >Continue</Button>
                                </Box>
                            }
                        </Grid>
                        <Grid item xs={12} sm={6} textAlign="right" sx={{mt:{xs:2}}}>
                            <img src="/images/img-1.png" width="100%" alt="" style={{border:'3px solid #fff',boxSizing:'border-box'}} />
                        </Grid>
                    </Grid>
                </Container>
            </EntrySection>
            <Container ref={mainRef}>
                {
                    (selectedTerm && chartData.length > 0) &&
                    <Box sx={{backgroundColor:'#fff',padding:'44px 20px'}}>
                        <h2>Estimated Total Savings = <RollingNumber data={energySavings} styles={{color:'#005daa',fontWeight:700}} /></h2>
                        <Grid container flexDirection={{xs:'column-reverse',sm:'row'}}>
                            <Grid container item xs={12} sm={7} alignItems="center" justifyContent={{xs:'center',sm:'flex-start'}} sx={{marginTop:{xs:3,sm:0}}}>
                                <span style={{marginRight:12}}>Avg. Monthly Use:</span>
                                <ButtonGroup sx={{marginTop:{xs:2,sm:0}}}>
                                    {
                                        ['500', '1000', '2000'].map((val, idx) => 
                                        <Button variant={avgMonthlyUse === val ? 'contained' : 'outlined'} key={idx} onClick={() => {
                                            setAvgMonthlyUse(val);
                                        }}>{`${val} kWh`}</Button>)
                                    }
                                </ButtonGroup>
                                {/* <SavingRow>Energy Savings: <NumberFormat value={energySavings} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} /></SavingRow> */}
                            </Grid>
                            <Grid container item xs={12} sm={5} alignItems="center" justifyContent="flex-end">
                                <div>
                                    <span style={{marginRight:12}}>Term:</span>
                                    <Select
                                        labelId="term-select-label"
                                        id="term-select"
                                        value={selectedTerm}
                                        onChange={(evt: SelectChangeEvent) => {
                                            setSelectedTerm(evt.target.value as string);
                                        }}
                                        disabled={loading}
                                    >
                                        {
                                            termOptions.map((val, idx) => <MenuItem value={val} key={idx}>{`${val} Months`}</MenuItem>)
                                        }
                                    </Select>
                                </div>
                            </Grid>
                        </Grid>
                        {/* <Grid container justifyContent="center" alignItems="center" sx={{mt:2,mb:4}}>
                            <span style={{marginRight:12}}>Avg. Monthly Use:</span>
                            <ButtonGroup>
                                {
                                    ['500', '1000', '2000'].map((val, idx) => 
                                    <Button variant={avgMonthlyUse === val ? 'contained' : 'outlined'} key={idx} onClick={() => {
                                        setAvgMonthlyUse(val);
                                    }}>{`${val} kWh`}</Button>)
                                }
                            </ButtonGroup>
                        </Grid> */}
                        <CompareChart
                            chartData={chartData}
                            handleShowPlan={(id: string) => {
                                setShowModal(true);
                                if (plans && plans[id]) {
                                    setOneRepPlanList(plans[id]);
                                }
                            }}
                        />
                        <Grid container spacing={4} alignItems="center" justifyContent="center" style={{marginTop:24}}>
                            <Grid item>For a personalized Savings Calculator based on your actual consumption, your choice of term and in your area click here</Grid>
                            <Grid item>
                                <Button size="large" variant="contained" onClick={() => {
                                    let quary = '';
                                    if (location && location.search) {
                                        const { search } = location;
                                        if (search.split('user=')[1] === 'eiq') {
                                            quary = '?user=eiq'
                                        }
                                    }
                                    navigate(`/compare${quary}`);
                                }}>See My Savings</Button>
                            </Grid>
                        </Grid>
                    </Box>
                }
            </Container>
            <Box style={{backgroundColor:'#005daa'}} sx={{mt:8,pt:5,pb:5}}>
                <Container>
                    <Grid container alignItems="center">
                        <Grid item xs={12} sm={6}>
                            <h2 style={{margin:0,color:'#fff'}}>Shop for your home</h2>
                        </Grid>
                        <Grid item xs={12} sm={6} textAlign={{xs:"center",sm:"right"}} sx={{marginTop:{xs:3,sm:0}}}>
                            <Button size="large" variant="secondary" onClick={() => {
                                window.location.href = "https://www.championenergyservices.com/Residential/Sign-Up"
                            }}>Sign Up</Button>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <PlanModal
                show={showModal}
                data={oneRepPlanList}
                handleCloseModal={() => { setShowModal(false); }}
            />
        </SectionWrapper>
    )
}

export default withSnackbar(SavingSection)